import styled, { css } from 'styled-components';
import { getButtonTextColors, getStyleForState, states } from '../helpers';
import Overlay from './Overlay';
import SpinnerWrapper from './SpinnerWrapper';
export const ButtonTextWrapper = styled.div `
	display: flex;
	align-items: center;
	min-height: 18px;
	margin: auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const getPadding = (props) => {
    let { vertical, withIcon, withoutIcon } = props;
    const { $type } = props;
    if ($type === 'outline') {
        vertical -= 2;
        withIcon -= 2;
        withoutIcon -= 2;
    }
    if ($type === 'neutral') {
        vertical -= 1;
        withIcon -= 1;
        withoutIcon -= 1;
    }
    if (props.$stretch)
        return `${vertical}px ${withoutIcon}px`;
    if (props.$leadingIcon && props.$trailingIcon)
        return `${vertical}px ${withIcon}px ${vertical}px ${withIcon}px`;
    if (props.$leadingIcon)
        return `${vertical}px ${withoutIcon}px ${vertical}px ${withIcon}px`;
    if (props.$trailingIcon)
        return `${vertical}px ${withIcon}px ${vertical}px ${withoutIcon}px`;
    return `${vertical}px ${withoutIcon}px`;
};
const getIconPadding = (type, size) => {
    let padding = 11;
    switch (size) {
        case 'small':
            padding = 7;
            break;
        case 'large':
            padding = 12;
            break;
        case 'medium':
        default:
            padding = 11;
    }
    if (type === 'neutral')
        padding -= 1;
    if (type === 'outline')
        padding -= 2;
    return `${padding}px`;
};
/* eslint-disable @typescript-eslint/indent */
const ButtonWrapper = styled.button `
	${p => p.$size === 'small' && p.theme.typography['Label.Small']()};
	${p => p.$size !== 'small' && p.theme.typography['Label.Medium']()};

	position: relative;

	/*
		This is sad. We need the "&&" override as the ops dash gets styles from bootstrap and we need
		a reliable way to override those without using "!important"
		When we will get rid of bootstrap, just remove the '&, && {' and leave the color property there.
	*/
	&, && {
		color: ${p => getButtonTextColors(p.theme, p.$type || 'primary')};
	}

	border-radius: ${p => {
    switch (p.$size) {
        case 'small':
            return '6px;';
        case 'large':
            return '10px;';
        case 'medium':
        default:
            return '8px';
    }
}};

	display: ${p => p.$display ?? 'flex'};
	align-items: center;
	text-align: center;
	transition: ease 0.2s;

	cursor: pointer;

	:active, :focus {
		outline: none;
		${p => !(['outline', 'neutral'].includes(p.$type || 'primary')) && 'border: none;'};
	};

	background: ${p => {
    switch (p.$type) {
        case 'outline':
        case 'text':
        case 'textMuted':
        case 'neutral': return p.theme.ui.textOnAction;
        case 'textOnFill': return 'transparent';
        case 'neutralMuted': return p.theme.ui.neutralActionMuted;
        case 'neutralMutedOnFill': return p.theme.ui.neutralActionMutedOnFill;
        case 'destructiveMuted': return p.theme.ui.dangerActionMuted;
        case 'destructive': return p.theme.ui.dangerAction;
        case 'primaryMuted': return p.theme.ui.primaryActionMuted;
        case 'primary': return p.theme.ui.primaryAction;
        default: return p.theme.ui.primaryAction;
    }
}};

	border: ${p => {
    switch (p.$type) {
        case 'outline':
            return `2px solid ${p.theme.ui.primaryAction}`;
        case 'neutral':
            return `1px solid ${p.theme.ui.borderSeparator}`;
        default:
            return 'none';
    }
}};

	padding: ${p => {
    if (p.$iconOnly)
        return getIconPadding(p.$type || 'primary', p.$size);
    if (p.$type?.startsWith('text'))
        return p.$size === 'small' ? '0 4px' : '2px 4px';
    switch (p.$size) {
        case 'small':
            return getPadding({
                ...p,
                vertical: 7,
                withIcon: 8,
                withoutIcon: 12,
            });
        case 'large':
            return getPadding({
                ...p,
                vertical: 14,
                withIcon: 16,
                withoutIcon: 20,
            });
        case 'medium':
        default:
            return getPadding({
                ...p,
                vertical: 10,
                withIcon: 12,
                withoutIcon: 16,
            });
    }
}};

	${p => p.$stretch && `
		width: 100%;
	`};
	${p => p.$stretch && !p.$leadingIcon && !p.$trailingIcon && `
		justify-content: center;
	`};

	${p => p.$stretch && p.$leadingIcon && `
		justify-content: space-between;
	`};

	${p => p.$stretch && p.$trailingIcon && `
		justify-content: space-between;
	`};

	&:hover {
		${Overlay} {
			${p => {
    const s = states(p.theme);
    const { hover } = s[p.$type || 'primary'];
    return getStyleForState(hover);
}}
		}
	}

	&:focus {
		${Overlay} {
			${p => {
    const s = states(p.theme);
    const { focus } = s[p.$type || 'primary'];
    return getStyleForState(focus);
}}
		}
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
		${p => {
    const s = states(p.theme);
    const { disabled } = s[p.$type || 'primary'];
    return getStyleForState(disabled);
}}
	}

	&:active {
		${Overlay} {
			${p => {
    const s = states(p.theme);
    const { press } = s[p.$type || 'primary'];
    return getStyleForState(press);
}}
		}
	}

	&, &:active, &:hover {
		text-decoration: none;
	}

	${p => p.$loading && css `
		> * {
			visibility: hidden;
		}

		> ${SpinnerWrapper} {
			visibility: visible;
		}
	`};
`;
/* eslint-enable @typescript-eslint/indent */
export default ButtonWrapper;
