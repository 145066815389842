/* eslint-disable @typescript-eslint/indent */
import { datadogLogs } from '@datadog/browser-logs';
export const configureDatadog = (_app) => {
    // eslint-disable-next-line no-process-env
    const env = process.env.NODE_ENV;
    const isProduction = env === 'production';
    datadogLogs.init({
        clientToken: 'pub3aa97dbae1508f9e7e7731c121f895d1',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: isProduction,
        sampleRate: 100,
        service: 'website',
    });
    datadogLogs.logger.setContext({ env });
    datadogLogs.logger.setLevel('error');
    // datadogLogs.logger.setHandler('console');
};
