import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import VisionaryContext from '~website/contexts/VisionaryContext';

interface TrustpilotThemeAwareProps extends React.HTMLAttributes<HTMLDivElement> { }

const TrustpilotThemeAware: React.FCWithChildren<TrustpilotThemeAwareProps> = props => {
	const theme = useContext(ThemeContext);
	const visionary = useContext(VisionaryContext);
	const dark = visionary || theme.themeKey === 'dark';
	const asset = useContentAsset('website_core', `trustpilot/${dark ? 'visionary' : 'focussed'}.svg`);

	return (
		<Wrap {...props}>
			<Image src={asset} alt={'trustpilot logo'} />
		</Wrap>
	);
};

const Wrap = styled.div`
	display: inline-block;
`;

const Image = styled.img`
	height: 30px;
`;

export default TrustpilotThemeAware;
