import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'sentence-case';

import { Header, HeaderTabsContainer, Hero } from '../components/atoms/background';
import Profile from '../containers/Profile';
import { Anchor } from '~lib/frontend/atoms';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '~lib/frontend/design-system/components/tabs';
import TabBackgroundContext from '~lib/frontend/design-system/components/tabs/context/tab-background-context';
import Typography from '~lib/frontend/design-system/components/Typography';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import WebsiteHeader from '~website/components/layout/header';
import VisionaryContext from '~website/contexts/VisionaryContext';
import Discounts from '~website/features/discounts/Discounts';

const tabs = [
	{ title: 'account details', content: <Profile />, link: '/me/account' },
	{ title: 'discounts', content: <Discounts />, link: '/me/discounts' },
];

interface ProfileWrapperProps {
	view: 'profile' | 'discounts';
}

const Title = () => (
	<Hero>
		<Typography $type={'Heading.Large'} $color={'textOnFill'}>
			{'Account'}
		</Typography>
	</Hero>
);

const ProfileWrapper = ({ view }: ProfileWrapperProps) => {
	const discountFlow = useConfigFlag('web_subscription_discount_flow') ?? false;

	const index = view === 'profile' ? 0 : 1;
	const title = discountFlow ? sentenceCase(tabs[index].title) : 'account details';

	return (
		<VisionaryContext.Provider value>
			<WebsiteHeader contentUnder />
			<Helmet>
				<title>{`Your ${title} | Cuvva`}</title>
			</Helmet>

			{discountFlow && (
				<TabBackgroundContext.Provider value={'transparent'}>
					<Tabs defaultIndex={index}>
						<Header>
							<Title />
							<HeaderTabsContainer>
								<TabList>
									{tabs.map(tab => (
										<Tab
											key={tab.title}
											as={Anchor}
											// @ts-ignore
											to={tab.link}
										>
											{sentenceCase(tab.title)}
										</Tab>
									))}
								</TabList>
							</HeaderTabsContainer>
						</Header>
						<TabPanels>
							{tabs.map(tab => (
								<TabPanel key={tab.title}>{tab.content}</TabPanel>
							))}
						</TabPanels>
					</Tabs>
				</TabBackgroundContext.Provider>
			)}

			{!discountFlow && (
				<Fragment>
					<Header>
						<Title />
					</Header>
					<Profile />
				</Fragment>
			)}

		</VisionaryContext.Provider>
	);
};

export default ProfileWrapper;
