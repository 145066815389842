import styled from 'styled-components';
const Overlay = styled.div `
	position: absolute;
	padding: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: calc(${p => {
    switch (p.size) {
        case 'small':
            return p.theme.borderRadius.extraSmall;
        case 'large':
            return p.theme.borderRadius.regular;
        case 'medium':
        default:
            return p.theme.borderRadius.small;
    }
}} - 1px);
`;
export default Overlay;
