import styled, { css } from 'styled-components';

import { media } from '~lib/frontend/design-system/breakpoints';
import { Theme, UIColors } from '~lib/frontend/design-system/types';

type GradientOption = 'leading' | 'center' | 'trailing' | 'none';

interface HeaderHeroWrapProps {
	$themeKey: Theme;
	$backgroundImage: string;
	$hasSubHero: boolean;
	$gradient: GradientOption;
}

function getGradient(gradient: GradientOption, uiColors: UIColors) {
	switch (gradient) {
		case 'leading':
			return uiColors.violetGradientLeading;
		case 'center':
			return uiColors.violetGradientCentre;
		case 'trailing':
			return uiColors.violetGradientTrailing;
		case 'none':
		default:
			return null;
	}
}

/* eslint-disable @typescript-eslint/indent */
const HeaderHeroWrap = styled.div<HeaderHeroWrapProps>`
	display: flex;
	align-items: center;

	background: ${p => {
		const isDark = p.$themeKey === 'dark';

		if (!isDark) return p.theme.ui.background;

		const backgrounds = [`linear-gradient(${p.theme.ui.secondaryBackground}, ${p.theme.ui.secondaryBackground})`];
		const gradient = getGradient(p.$gradient, p.theme.ui);

		if (gradient) backgrounds.unshift(gradient);

		return backgrounds.join(', ');
	}};

	min-height: 822px;

	@media (max-width: 767px) {
		min-height: ${p => (p.$hasSubHero ? 'unset' : '600px')};
	}

	${p => {
		if (!p.$backgroundImage) return '';

		return css`
			${media.greaterThan('tablet')`
				background: ${(() => {
					const isDark = p.$themeKey === 'dark';
					const backgrounds = [`url(${p.$backgroundImage})`];

					if (!isDark) {
						backgrounds.push(p.theme.ui.background);

						return backgrounds.join(', ');
					}

					const gradient = getGradient(p.$gradient, p.theme.ui);

					if (gradient)
						backgrounds.push(gradient);

					backgrounds.push(p.theme.ui.secondaryBackground);

					return backgrounds.join(', ');
				})()};
				background-position: bottom center;
				background-repeat: no-repeat;
				background-size: cover;
			`}
		`;
	}}
`;

export default HeaderHeroWrap;
