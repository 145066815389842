export const icons = [
    'ic_accelerating',
    'ic_account_circle',
    'ic_account',
    'ic_add_fill',
    'ic_add_outline',
    'ic_alert',
    'ic_apple',
    'ic_arrival',
    'ic_arrow_topright_bottomleft',
    'ic_arrow_up_down',
    'ic_bin',
    'ic_bolt',
    'ic_box_arrow_right',
    'ic_breaking',
    'ic_calendar_today',
    'ic_calendar_tomorrow',
    'ic_calendar',
    'ic_camera',
    'ic_car',
    'ic_card_licence',
    'ic_card_payment',
    'ic_card_sf',
    'ic_chat_outline',
    'ic_chat_pointer_flat',
    'ic_chat_pointer',
    'ic_check_circle_fill',
    'ic_check_circle',
    'ic_check_small',
    'ic_check',
    'ic_chevron_down_circle',
    'ic_chevron_down_sm',
    'ic_chevron_down',
    'ic_chevron_left_sm',
    'ic_chevron_left_small',
    'ic_chevron_left',
    'ic_chevron_right_sm',
    'ic_chevron_right_small',
    'ic_chevron_right',
    'ic_chevron_up_circle_fill',
    'ic_chevron_up_sm',
    'ic_chevron_up',
    'ic_clip',
    'ic_clipboard',
    'ic_clock',
    'ic_close_rounded',
    'ic_code',
    'ic_cog',
    'ic_comprehensive',
    'ic_contacts',
    'ic_cookie',
    'ic_copy',
    'ic_corners',
    'ic_cross_circle',
    'ic_cross_circle_fill',
    'ic_cross_outline',
    'ic_cross_small',
    'ic_cross',
    'ic_cuvva',
    'ic_dark_mode',
    'ic_departure',
    'ic_device_android_ringing',
    'ic_device_android',
    'ic_device_android_download',
    'ic_device_iphone_outgoing',
    'ic_device_iphone_ringing',
    'ic_device_iphone',
    'ic_document',
    'ic_drag',
    'ic_dribbble',
    'ic_edit',
    'ic_email',
    'ic_environment',
    'ic_exclamation_circle',
    'ic_exclamation',
    'ic_expand',
    'ic_external_link',
    'ic_facebook',
    'ic_filter',
    'ic_fingerprint',
    'ic_flash_auto',
    'ic_flash_off',
    'ic_focusing',
    'ic_freeze',
    'ic_friends',
    'ic_gauge',
    'ic_geo',
    'ic_github',
    'ic_heart_fill',
    'ic_heart',
    'ic_help',
    'ic_home',
    'ic_important_circle',
    'ic_info_alert',
    'ic_info_circle_fill',
    'ic_info_circle',
    'ic_info',
    'ic_instagram',
    'ic_intercom',
    'ic_keyboard',
    'ic_linkedin',
    'ic_loading_spinner',
    'ic_location_material',
    'ic_location',
    'ic_lock',
    'ic_logout',
    'ic_minus_circle_fill',
    'ic_minus_outline',
    'ic_minus',
    'ic_more',
    'ic_mot',
    'ic_navigation_back',
    'ic_navigation_burger',
    'ic_navigation_down',
    'ic_navigation_exit',
    'ic_navigation_forward',
    'ic_navigation_up',
    'ic_notes',
    'ic_parking',
    'ic_percentfill',
    'ic_petrol',
    'ic_phone_inbound',
    'ic_phone_outline',
    'ic_phone',
    'ic_pin',
    'ic_plane',
    'ic_play_circle_fill',
    'ic_plus',
    'ic_plus_circle',
    'ic_policy_outline',
    'ic_policy',
    'ic_profile',
    'ic_push_down',
    'ic_question_circle_fill',
    'ic_question_circle',
    'ic_question_fill',
    'ic_question_mark',
    'ic_question',
    'ic_quote',
    'ic_recent',
    'ic_repeat',
    'ic_retry',
    'ic_rotate',
    'ic_search',
    'ic_selfie',
    'ic_share_androd',
    'ic_share_ios',
    'ic_sidebar',
    'ic_signal',
    'ic_slack',
    'ic_slider',
    'ic_speakerdeck',
    'ic_stackoverflow',
    'ic_star',
    'ic_stripe',
    'ic_subscription',
    'ic_suitcase',
    'ic_support',
    'ic_tag_solid',
    'ic_tasks',
    'ic_tax_fill',
    'ic_tax',
    'ic_timer',
    'ic_tools',
    'ic_tree',
    'ic_try_again',
    'ic_twitter',
    'ic_unfreeze',
    'ic_visibility_off',
    'ic_visibility_on',
    'ic_wand',
    'ic_zigzag',
];
