import React from 'react';
import styled from 'styled-components';

import Card, { CardProps, contentAdapter as cardContentAdapter } from './Card';
import TitleSegment, { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from './TitleSegment';
import { media } from '~lib/frontend/design-system/breakpoints';
import { GetterType } from '~lib/frontend/types/content';

interface TitleSegmentCardsColumnProps extends TitleSegmentProps {
	cards: CardProps[];
}
const TitleSegmentCardsColumn: React.FCWithChildren<TitleSegmentCardsColumnProps> = props => {
	const {
		cards,
		...titleSegmentProps
	} = props;

	return (
		<TitleSegment {...titleSegmentProps} centered>
			<Wrapper>
				{cards.map((card, i) => (
					<Card
						key={`${i}:${card.title}`}
						{...card}
					/>
				))}
			</Wrapper>
		</TitleSegment>
	);
};

export const contentAdapter = (get: GetterType): TitleSegmentCardsColumnProps => {
	const titleSegment = titleSegmentContentAdapter(get);
	const cardsArr = get<unknown[]>('cards');
	const cards: CardProps[] = [];

	if (!cardsArr?.length) {
		return {
			cards,
			...titleSegment,
		};
	}

	for (let i = 0; i < cardsArr.length; i++)
		cards.push(cardContentAdapter((key, fallback) => get(`cards[${i}].${key}`, fallback)));

	return {
		cards,
		...titleSegment,
	};
};

const Wrapper = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: ${p => p.theme.spacing.extraLarge};
	grid-row-gap: 24px;

	padding-top: 62px;

	${p => media.lessThan('laptop')`
		display: flex;
		flex-direction: column;
		justify-content: center;

		> div {
			flex: 1;

			/* Remove padding from inside card */
			width: calc(100% - ${p.theme.spacing.extraLarge} - ${p.theme.spacing.extraLarge});
			margin: 0 auto;
			margin-bottom: 15px;
		}
	`}
`;

export default TitleSegmentCardsColumn;
